import React from 'react'
import BookletSummary from '../components/booklet-summary'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/layout';

const Topic = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title;
  const topic = data.allContentfulTopic.edges[0].node;
  const bookletItems = topic.booklet
    .sort((a, b) => a.ordinal - b.ordinal)
    .map(b => {
      return <li key={b.id}><BookletSummary booklet={b} /></li>
    });

  return (
    <Layout>
      <div className="post">
        <Helmet title={`${topic.title} | ${siteTitle}`} />
        <h2 className="title">{topic.title}</h2>
        <ul>
          {bookletItems}
        </ul>
      </div>
    </Layout>
  );
}

export default Topic

export const topicDetailFragment = graphql`
  fragment topicDetail on ContentfulTopic {
    id
    title
    ordinal
    fields {
      path
    }
  	booklet {
      ...bookletSummary
    }
  }
`;


export const topicQuery = graphql`
  query TopicQuery($id: String!) {
    site {
      ...siteMeta
    }
    allContentfulTopic(filter: { id: { eq: $id } }) {
      edges {
        node {
          ...topicDetail
        }
      }
    }
  }`
